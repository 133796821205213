<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'StaffList',
              }"
              >{{ displayTextStaff('staff_management', '帳號管理') }}</b-breadcrumb-item
            >
            <b-breadcrumb-item active>{{ displayTextStaff('staff_list', '帳號列表') }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="d-flex flex-column flex-xl-row align-items-start align-items-xl-center justify-content-between mb-4 mb-xl-2">
        <h4 class="mb-2 mb-xl-0 font-weight-bold">{{ displayTextStaff('staff_list', '帳號列表') }}</h4>
        <div
          class="
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
        <b-button
          v-if="checkPermission([consts.STAFF_MODIFY])"
          class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
          variant="primary"
          @click="exportStaff()"
          >
          {{ displayTextStaff("staff_export", "匯出員工") }}
        </b-button>
          <b-button
            v-if="checkPermission([consts.STAFF_MODIFY])"
            v-b-modal.modal-upload
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
            ><i class="fa fa-plus"></i>
            {{ displayTextStaff("staff_import", "批次上傳") }}
          </b-button>
          <b-button
            v-if="checkPermission([consts.STAFF_MODIFY])"
            class="flex-shrink-0 mb-2 mb-xl-0"
            variant="primary"
            :to="{ name: 'StaffCreate' }"
            ><i class="fa fa-plus"></i>{{ displayTextStaff("staff_create", "新增帳號") }}</b-button
          >

          <b-input-group class="ml-0 ml-xl-4">
            <b-form-input
              v-model="keyword"
              v-on:keyup.enter="getStaffs(1)"
              :placeholder="displayTextStaff('staff_search', '搜尋')"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="getStaffs(1)"
                ><i class="fa fa-search"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>

          <b-button v-b-toggle.collapse-1-inner variant="link" class=" flex-shrink-0 ml-0 ml-xl-1">
            {{ displayTextStaff('staff_advance_search', '進階搜尋') }}<i class="fa fa-chevron-down"></i>
          </b-button>
        </div>
        <!-- <div class="col-12 d-flex justify-content-end mt-1 advance-search">
          <div @click="() => showAdvanceSearch = !showAdvanceSearch">
            進階搜尋<template v-if="showAdvanceSearch">-</template><template v-else>+</template>
          </div>
        </div> -->

      </div>

      <b-collapse id="collapse-1-inner" class="mt-2">
        <div class="border p-2">
          <b-form class="">
            <div class="d-flex flex-column flex-xl-row">
              <b-form-group
                v-if="hiddenSearchField('has_branch')"
                :label="displayTextStaff('staff_search_status', '帳號狀態')"
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="7"
                style="width: 100%"
              >
                <b-form-select
                  v-if="checkPermission([consts.STAFF_LIST_VIEW_NO_BRANCH])"
                  v-model="query.has_branch"
                  :options="hasBranchOptions"
                  class="mr-2"
                  @change="getStaffs(1)"
                >
                </b-form-select>
              </b-form-group>

              <b-form-group
                v-if="hiddenSearchField('has_line_id')"
                :label="displayTextStaff('staff_search_line_id', 'Line ID')"
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="7"
                style="width: 100%"
              >
                <b-form-select
                  v-model="query.has_line_id"
                  :options="hasLineIdOptions"
                  class="mr-2"
                  @change="getStaffs(1)"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="d-flex flex-column flex-xl-row">
              <b-form-group
                v-if="hiddenSearchField('is_enbled') && hiddenSearchField('branch_id')"
                :label="displayTextStaff('staff_belong_branch', '所屬分店')"
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="10"
                style="width: 100%"
              >
                <div class="d-flex">
                  <b-form-select
                    v-if="hiddenSearchField('is_enbled')"
                    v-model="query.is_enabled"
                    :options="isEnableOptions"
                    class="mr-2 d-inline-flex"
                    @change="getStaffs(1)"
                  ></b-form-select>
                  <b-form-select
                    v-if="hiddenSearchField('branch_id')"
                    v-model="query.branch_id"
                    :options="
                      checkPermission([
                        consts.STAFF_LIST_VIEW_ALL_BRANCH,
                        consts.BRANCH_LIST_VIEW,
                      ])
                        ? allBranchOptions
                        : branchOptions
                    "
                    @change="getStaffs(1)"
                    class="mr-2 d-inline-flex"
                  >
                    <template #first>
                      <b-form-select-option :value="null"
                        >{{ displayTextStaff('staff_all_branch', '所有分店') }}</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </div>
              </b-form-group>
              <b-form-group
                v-if="hiddenSearchField('dept_name')"
                :label="displayTextStaff('staff_search_dept_name', '部門')"
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="7"
                style="width: 100%"
              >
                <b-form-select
                  v-model="query.dept_name"
                  :options="deptNameOptions"
                  @change="getStaffs(1)"
                  class="mr-2 d-inline-flex"
                >
                <template #first>
                    <b-form-select-option :value="null"
                    >{{ displayTextStaff('staff_all_dept_name', '所有部門') }}</b-form-select-option
                    >
                </template>
                </b-form-select>
              </b-form-group>
              <!-- <div style="width: 100%"></div> -->
            </div>
            <div class="d-flex">
              <b-form-group
                v-if="hiddenSearchField('roles')"
                :label="displayTextStaff('staff_search_roles', '角色')"
                label-cols-sm="1"
                content-cols-sm
                content-cols-lg="11"
                style="width: 100%"
              >
                <b-form-checkbox-group
                  v-model="query.roles"
                  class="pt-1"
                  :options="roleOptions"
                  @change="getStaffs(1)"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </b-form>
        </div>
      </b-collapse>

      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="staffs"
                :fields="fields"
              >
              <template #cell(avatar_url)="data">
                <div class="d-flex align-items-center justify-content-center">
                  <b-avatar
                    :src="data.item.avatar_url"
                    variant="secondary"
                    size="2rem"
                  ></b-avatar>
                </div>
              </template>
                <template #cell(actions)="data">
                  <b-dropdown id="ddown23" variant="secondary">
                    <template slot="button-content">
                      <i class="mdi mdi-menu"></i>
                    </template>
                    <b-dropdown-item
                      :to="{
                        name: 'StaffView',
                        params: { staff_id: data.item.id },
                      }"
                    >
                      {{ displayTextStaff('staff_view_action', '查看') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="checkPermission([consts.STAFF_MODIFY])"
                      :to="{
                        name: 'StaffForm',
                        params: { staff_id: data.item.id },
                      }"
                    >
                      {{ displayTextStaff('staff_edit_action', '編輯') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="checkPermission([consts.STAFF_BRANCH_MODIFY])"
                      :to="{
                        name: 'StaffSetBranch',
                        params: { staff_id: data.item.id },
                      }"
                    >
                      {{ displayTextStaff('staff_set_branch_action', '設定所屬分店') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="checkPermission([consts.STAFF_CUSTOMER_VIEW])"
                      :to="{
                        name: 'StaffCustomerList',
                        params: { staff_id: data.item.id },
                      }"
                    >
                      {{ displayTextStaff('staff_set_customer_action', '設定客戶') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="checkPermission([consts.STAFF_ROLE_MODIFY])"
                      :to="{
                        name: 'StaffSetRole',
                        params: { staff_id: data.item.id },
                      }"
                    >
                      {{ displayTextStaff('staff_set_role_action', '設定角色') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="checkPermission([consts.STAFF_CHANGE_PASSWORD])"
                      :to="{
                        name: 'StaffChangePassword',
                        params: { staff_id: data.item.id },
                      }"
                    >
                      {{ displayTextStaff('staff_change_password_action', '變更密碼') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="checkPermission([consts.STAFF_DELETE])"
                      @click="deleteStaff(data.item)"
                    >
                      {{ displayTextStaff('staff_delete_action', '刪除') }}
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="10"
            align="center"
            @change="getStaffs"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>

    <b-modal id="modal-upload" :title="displayTextStaff('staff_import', '批次上傳')">
      <b-overlay :show="showLoadingUpload">
        <div class="form-group">
          <label class="flex-shrink-0 mr-2 mb-0">{{ displayTextStaff('staff_import_text', '請上傳帳號 Excel 檔案') }}</label>
          <b-form-file plain @input="uploadStaff"></b-form-file>
        </div>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="
            getSampleExcelFile(
              'staff_import_xls',
              '/excel/staff_list.xlsx'
            )
          "
          ><i class="fa fa-file-text"></i>{{ displayTextStaff('staff_import_download_text', '下載範例檔案') }}</b-button
        >
        <b-button
          variant="outline-danger"
          size="sm"
          class="float-right"
          @click="cancel()"
        >
          {{ displayTextStaff('staff_import_close', '關閉') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import staffApi from "@/apis/staff";
import roleApi from "@/apis/role";
import branchApi from "@/apis/branch";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import { getSampleExcelFile, displayText } from "@/utils/dashboardUtils";

export default {
  data() {
    return {
      consts: consts,
      currentPage: 1,
      totalRows: 1,
      showLoading: true,
      showLoadingUpload: false,
      showAdvanceSearch: false,
      query: {
        has_branch: null,
        has_line_id: null,
        is_enabled: true,
        branch_id: null,
        roles: [],
        dept_name: null,
        include_child_branch: false,
      },
      hasBranchOptions: [
        { value: null, text: this.displayTextStaff('staff_show_all_branch', '帳號無論有無分店都顯示') },
        { value: true, text: this.displayTextStaff('staff_show_have_branch', '只顯示有分店的帳號') },
        { value: false, text: this.displayTextStaff('staff_show_no_branch', '只顯示沒有分店的帳號') },
      ],
      hasLineIdOptions: [
        { value: null, text: this.displayTextStaff('staff_show_all_line_id', '帳號無論有無 LineUID 都顯示') },
        { value: true, text: this.displayTextStaff('staff_show_have_line_id', '只顯示有 LineUID 的帳號') },
        { value: false, text: this.displayTextStaff('staff_show_no_line_id', '只顯示沒有 LineUID 的帳號') },
      ],
      isEnableOptions: [
        { value: null, text: this.displayTextStaff('staff_show_all_account', '帳號無論有無啟用都顯示') },
        { value: true, text: this.displayTextStaff('staff_show_enabled_account', '只顯示有啟用的帳號') },
        { value: false, text: this.displayTextStaff('staff_show_disabled_account', '只顯示未啟用的帳號') },
      ],
      roleOptions: [],
      allBranchOptions: [],
      deptNameOptions:[],
      staffs: [],
      keyword: null,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters('general', ['branchOptions', 'role']),
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),
    fields() {
      let fields = [
        {
          key: "avatar_url",
          label: "",
        },
        {
          key: "employee_code",
          label: this.displayTextStaff("staff_employee_code", "員工編號"),
          sortable: true,
        },
        {
          key: "name",
          label: this.displayTextStaff("staff_name", "姓名"),
        },
        {
          key: "branch_name",
          label: this.displayTextStaff("staff_branch_name", "分區"),
        },
        {
          key: "dept_name",
          label: this.displayTextStaff("staff_dept_name", "部門"),
        },
        {
          key: "mobile_phone",
          label: this.displayTextStaff("staff_mobile_phone", "手機"),
        },
        {
          key: "email",
          label: this.displayTextStaff("staff_email", "E-Mail"),
        },
        {
          key: "display_status",
          label: this.displayTextStaff("staff_status", "狀態"),
        },
        {
          key: "actions",
          label: this.displayTextStaff("staff_action", "操作"),
        },
      ]
      const hiddenFields = this.getModuleConfig("staff", "staff.hidden_fields")

      if (hiddenFields) {
        fields = fields.filter(field => !hiddenFields.includes(field.key))
      }
      return fields
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    getSampleExcelFile,
    displayText,
    hiddenSearchField(fieldName) {
      let isShow = true;
      if (this.getModuleConfig("staff", "staff.hidden_fields")) {
        isShow = !this.getModuleConfig("staff", "staff.hidden_fields")?.includes(fieldName);
      }
      return isShow;
    },
    displayTextStaff(key, defaultText) {
      if (this.fromPartnerPage) {
        return displayText(`partner.${key}`, defaultText);
      } else {
        return displayText(`staff.${key}`, defaultText);
      }
    },
    async getManagedOptions() {
      const { data } = await staffApi.getManagedStaffOptions();
      this.deptNameOptions = data.data.dept_name.map((dept) => {
        return {
          value: dept,
          text: dept,
        };
      });
    },
    async init() {
      await this.getStaffs();
      await this.getRoleOptions();
      await this.getManagedOptions();
      if (this.checkPermission([consts.STAFF_LIST_VIEW_ALL_BRANCH, consts.BRANCH_LIST_VIEW])) {
        await this.getAllBranches();
      }
    },
    async getStaffs(page) {
      this.showLoading = true;
      try {
        let params = {
          page: page || this.currentPage,
          ...this.query
        };
        if (this.keyword) params.keyword = this.keyword;
        const { data } = await staffApi.getManagedStaffs(params);
        this.staffs = [];
        const now = new Date();
        data.data.forEach((staff) => {
          if (!staff.is_sys_admin) {
            this.staffs.push(staff);
          }
          staff.display_status = staff.is_enabled ? "啟用" : "未啟用";
          staff.branch_name = staff.current_branch ? staff.current_branch.name : "";
          if (staff.left_at) {
            const leftAt = new Date(staff.left_at);
            staff.display_status =
              leftAt.getTime() > now.getTime() ? staff.display_status : this.displayTextStaff("staff_resigned", "離職");
          }
        });
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: "取得帳號資料錯誤",
          });
        }
      }
      this.showLoading = false;
    },
    async getRoleOptions() {
        const { data } = await roleApi.getSubordinate(this.organization.id);
        this.roleOptions = data.data.map(role => {
          return { value: role.code, text: role.name };
        });
        this.roleOptions.push({ value: 'no-role', text: '無角色' });
        this.query.roles = data.data.map(role => role.code);
    },
    async getAllBranches() {
      const { data } = await branchApi.getBranches(this.organization, { is_all: true });
      this.allBranchOptions = data.data
        .sort((a, b) => {
          if (a.level === b.level) {
            return a.order - b.order;
          }
          return a.level - b.level;
        })
        .map(branch => {
          if (branch.level > 1) {
            return { value: branch.id, text: '-'.repeat(branch.level-1) + ` ${branch.name}` };
          }
          return { value: branch.id, text: branch.name };
        });
    },
    async deleteStaff(staff) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>員工編號：${staff.employee_code}</div>
                <div>姓名：${staff.name}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              await staffApi.removeStaff(this.organization, staff);
                this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "成功刪除 " + staff.name,
                });
                this.getStaffs();
            } catch (error) {
              if (error.response.data.message) {
                this.$swal.fire({
                  title: "錯誤",
                  type: "error",
                  text: error.response.data.message,
                });
              }
            }
          }
        });
    },
    async exportStaff() {
      let query = this.query;
      const filteredQuery = Object.keys(query).reduce((acc, key) => {
        if (
          query[key] !== null &&
          query[key] !== undefined &&
          query[key] !== ""
        ) {
          acc[key] = query[key];
        }
        return acc;
      }, {});
      if (this.keyword) filteredQuery.keyword = this.keyword;
      staffApi.exportManagedStaffs(filteredQuery).then(() => {
        this.$swal({
          title: '成功',
          text: "請至資料匯出查看結果",
          type: 'success',
          showCancelButton: true,
          confirmButtonText: '前往查看',
          cancelButtonText: '確認'
        }).then((result)=>{
            if (result.value) {
              this.$router.push({
                name: "MyConsoleDownloadFileList",
                params: { org_code: this.$route.params.org_code },
              });
            } else {
              return;
            }
          });
      });
    },
    async uploadStaff(file) {
      this.showLoadingUpload = true;
      let formData = new FormData();
      formData.append("file", file);

      try {
        let response = await staffApi.uploadStaff(formData);

        if (response.status === 200 && response.data.status === "success") {
          this.$swal.fire({
            title: "上傳成功",
            type: "success",
          });

          this.getStaffs();
        }
      } catch (e) {
        console.error(e);
        if (e.response.status === 422 && e.response.data.message[0]) {
          this.$swal.fire({
            title: "檔案內容有誤",
            type: "error",
            text: e.response.data.message[0] ?? null,
          });
        } else {
          this.$swal.fire({
            title: "上傳失敗",
            type: "error",
          });
        }
      } finally {
        this.showLoadingUpload = false;
        this.$bvModal.hide("modal-upload");
      }
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
  },
};
</script>

<style lang="scss">
.modal-backdrop {
  opacity: 0.5;
}

.advance-search {
  color: #337ab7;

  &:hover {
    color: #23527c;
    text-decoration: underline;
  }
}
</style>
